<template>
  <div class="contain" v-title data-title="结算页">
    <div class="paymethod">
      <div class="pa">请选择取货方式</div>
      <div class="pb">
        <van-radio-group v-model="offerMethod">
          <div class="pb-item" v-for="(item, index) in offerMethodList" :key="index" @click="changeRadio(item)">
            <div class="flex" style="flex: 1;align-items: center">
              <span class="bold" style="margin-left: 10px">{{item.name}}</span>
            </div>
            <div>
              <van-radio :name="item.value" checked-color="#E40404" />
            </div>
          </div>
        </van-radio-group>
      </div>
      <div class="pa" v-if="!addtessStatus2">
        <van-field v-model="userName" label="请输入姓名" placeholder="请输入姓名" />
        <van-field v-model="userPhone" label="请输入电话" type="tel" placeholder="请输入电话" />
      </div>
    </div>
    <div class="addressnav" v-if="addtessStatus2">
      <div class="address" v-if="addtessStatus" @click="toAddress">
        <div style="width: 10%">
          <img src="@/assets/img/positioning.png" style="width: 20px;height: 20px;vertical-align: middle" />
        </div>
        <div style="flex: 1">
          <div class="bold">
            <span class="fz16">{{addressData.addressContactName}} </span>
            <span class="fz16"> {{addressData.addressContactPhone}}</span>
          </div>
          <div class="fz12" style="margin-top: 6px;">{{addressData.addressPname}}{{addressData.addressCname}}{{addressData.addressAname}}{{addressData.addressDetail}}</div>
        </div>
        <div>
          <van-icon name="arrow" size="20px"/>
        </div>
      </div>
      <div class="address" v-else @click="toAddress">
        <div class="fz14" style="flex: 1">
          <img src="@/assets/img/positioning.png" style="width: 20px;height: 20px;vertical-align: middle" />请选择收货地址
        </div>
        <div>
          <van-icon style="vertical-align: -3px" name="arrow" size="20px"/>
        </div>
      </div>
    </div>
    <div class="order">
      <div class="order1">
        <div v-for="(item, index) in dataList" :key="index">
          <div class="oa">
            <van-icon name="shop-o" size="18px"/>
            <span class="fz13 bold" style="vertical-align: 4px">{{item.shopName}}</span>
          </div>
          <div class="ob" v-for="(item2, index2) in item.clearEventVo" :key="index2">
            <div class="ob-1" v-if="item2.youhuiName">
              <div style="margin-right: 4px">
                <span style="padding: 2px 5px;background-color: #E40404;color: #fff;border-radius: 3px">{{item2.youhuiName}}</span>
              </div>
              <div v-if="item2.eventType === 10">{{item2.ruleName}}</div>
              <template v-else>
                <div>{{item2.eventMessageYm || ''}}</div>
                <div>{{item2.eventMessageWm || ''}}</div>
              </template>
            </div>
            <div class="ob-2" v-for="(item3, index3) in item2.clearProduct" :key="index3">
              <div style="width: 28%">
                <van-image width="77px" height="77px" :src="img_url + item3.proImage" />
              </div>
              <div style="flex: 1">
                <div class="fz14 bold">[{{item3.brandName}}]{{item3.proName}}</div>
                <div class="cob3">
                  <span style="color: #D7A963" v-if="item3.proAlias">[{{item3.proAlias}}]</span>
                  <span>{{item3.specName}}{{item3.unitName}}</span>
                </div>
                <div class="mt10">
                  <span class="bold fz14">¥{{item3.onlinePrice}} </span>
                  <span class="cob3 fz12" style="text-decoration: line-through;margin-left: 4px;">¥{{item3.marketPrice}}</span>
                </div>
                <div class="mt5" v-if="item3.dptCumPro && item3.dptCumPro.num && item3.dptCumPro.cum">
                  <img src="@/assets/img/jifen.png" style="width: 16px;height: 15px;vertical-align: middle">
                  <span style="vertical-align: middle;margin-left: 6px">每满{{item3.dptCumPro.num}}件赠送{{item3.dptCumPro.cum}}积分</span>
                </div>
              </div>
              <div style="align-self: center">
                x{{item3.productNum}}
              </div>
            </div>
            <div class="ob-2" v-if="item2.clearGiftVo && item2.clearGiftVo.length">
              <div style="width: 15%" v-if="item2.giftStatus">赠品：</div>
              <div class="fz14 bold">
                <div v-for="(item3, index3) in item2.clearGiftVo" :key="index3">
                  <span v-if="item3.proSpecificationUnit">{{item3.proName}} x {{item3.proNumber}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="order2">
        <div class="oc">
          <van-field class="ocinput" v-model="note" maxlength="70" placeholder="给商家留言，最多70个字" type="textarea" rows="2" show-word-limit/>
        </div>
        <!--        <div class="od">-->
        <!--          共{{totalNum}}件商品-->
        <!--        </div>-->
        <div class="oe">
          <div class="oe-item">
            <div>商品数量：</div>
            <div>共<span class="bold">{{totalNum}}</span>件商品</div>
          </div>
          <div class="oe-item">
            <div>市场价：</div>
            <div style="text-decoration: line-through;">¥{{marketPrice}}</div>
          </div>
          <div class="oe-item">
            <div>线上价：</div>
            <div>¥{{clearPrice}}</div>
          </div>
          <div class="oe-item">
            <div>促销立减：</div>
            <div>-¥{{eventPrice}}</div>
          </div>
          <div class="oe-item coe4 bold">
            <div>商家返利：</div>
            <div>-¥{{rebatePrice}}</div>
          </div>
          <div class="oe-item">
            <div>运费：</div>
            <div>¥{{deliverPrice}}</div>
          </div>
          <div class="oe-item">
            <div>积分抵扣：</div>
            <div>-¥{{jiFenPrice}}</div>
          </div>
          <div class="oe-item">
            <div>
              积分规则<van-icon name="question" size="18" color="#E84016" style="vertical-align: -4px" @click="showJiFenRule"/>
            </div>
          </div>
          <template v-if="dataList.length < 2">
            <div class="oe-item" v-if="jiFenValid === 1">
              <div style="width: 70%">{{jiFenText}}</div>
              <div style="display: flex;align-items: center">
                <van-field class="jifeninput" type="digit" placeholder="0" v-model="jiFenVal" @blur="jiFenInput"/>
                <span style="color: #ccc">积分</span>
              </div>
            </div>
            <div class="oe-item3" v-else>
              <span>{{jiFenText}}</span>
            </div>
          </template>
        </div>
        <div class="of">
          <div class="of-item">
            <div>实付金额</div>
            <div class="fz14 bold">¥{{price}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="order">
      <div class="order2">
        <div class="of">
          <div class="of-item" style="display: block">
            <img src="@/assets/img/jifen.png" style="width: 16px;height: 15px;vertical-align: middle">
            <span style="vertical-align: -1px;">订单完成后，预计能获得{{jiFenYuJiL}}积分</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="bl">
        <span class="fz14">合计：</span>
        <span class="fz18 coe4 bold"><span class="fz12">￥</span>{{price}}</span>
      </div>
      <div class="br" @click="submit">提交订单</div>
    </div>
    <van-popup v-model="jiFenRuleStatus">
      <div class="jifenpop">
        <div class="fz16 bold" style="text-align: center">积分规则</div>
        <div class="jifen-line">
          <div class="jifen-title">如何获取</div>
          <div class="jifen-text">
            <div v-for="(item, index) in jiFenRule.rhhq" :key="index">
              <span>{{index + 1}}、</span>
              <span>{{item}}</span>
            </div>
          </div>
        </div>
        <div class="jifen-line">
          <div class="jifen-title">如何使用</div>
          <div class="jifen-text">
            <div v-for="(item, index) in jiFenRule.rhsy" :key="index">
              <span>{{index + 1}}、</span>
              <span>{{item}}</span>
            </div>
          </div>
        </div>
        <div class="jifen-line">
          <div class="jifen-title">退还规则</div>
          <div class="jifen-text">
            <div>1、买家取消/卖家作废订单，可全额退还该笔订单使用的积分；</div>
            <div>2、订单发生退单，该笔订单所使用的积分不退还；</div>
          </div>
        </div>
        <div class="jifen-line">
          <div class="jifen-title">其他说明</div>
          <div class="jifen-text">
            <div>1、积分兑换比例：1元=100积分；</div>
            <div>2、如果会员账号被暂停使用，则暂停/取消该账号积分的使用权益；</div>
            <div>3、积分的最终解释权，归商家所有；</div>
            <div>4、多店铺结算时不能使用积分；</div>
          </div>
        </div>
        <div class="jifen-line" style="text-align: center">
          <van-button type="danger" size="small" @click="closeJiFenRule">我知道了</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import tt from '@/assets/js/ttjssdk'
import wx from 'weixin-js-sdk'
import { switchYouHui, accAdd, subtr } from '@/common/util'
import { Toast, Dialog } from 'vant'
import {
  selectShoppingCartDefaultAddress,
  selectCatAndClear,
  dptCommonVerify,
  selectOrdIsUsingCumScore,
  queryDptCumScoreRule,
  getwxmindata
} from '../../common/api'

export default {
  name: 'confirmOrderDY',
  data () {
    return {
      addtessStatus: true,
      addtessStatus2: true,
      addressData: '',
      dataList: [],
      note: '',
      ordPaymentMethod: '',
      paymethodList: [],
      pcEnum: '',
      // --------------//
      sessionID: '',
      proData: '',
      totalNum: 0,
      eventPrice: 0,
      marketPrice: 0,
      clearPrice: 0,
      rebatePrice: 0,
      deliverPrice: 0,
      price: 0,
      initPrice: 0,
      jiFenPrice: 0,
      ordResource: '',
      jsCode: '',
      img_url: process.env.VUE_APP_IMG_URL,
      infoStatus: true,
      // 积分
      jiFenRuleStatus: false,
      jiFenVal: 0,
      jiFenMax: 0,
      jiFenValid: 0,
      jiFenText: '',
      jiFenRule: '',
      jiFenYuJiL: 0,
      // areaList,
      infoPop: false,
      selectAddress: false,
      addressVal: '',
      reData: '',
      appId: '',
      userName: '',
      userPhone: '',
      offerMethod: 'address',
      offerMethodList: [
        {
          name: '使用所选地址',
          value: 'address'
        },
        {
          name: '上门自取',
          value: 'get'
        }
      ]
    }
  },
  async mounted () {
    this.sessionID = this.$route.query.sessionID
    localStorage.setItem('sessionID', this.sessionID)
    this.appId = this.$route.query.appId
    localStorage.setItem('appId', this.appId)
    this.jsCode = this.$route.query.jsCode
    this.reData = this.$route.query.data
    const address = this.$route.query.address
    this.ordResource = 'DY'
    if (address === 'false') {
      this.getAddress()
    } else {
      this.addressData = JSON.parse(address)
    }
    // 小程序需要解析才能获取数据
    await this.getwxmindata()
    await this.getList()
    await this.getJiFen()
  },
  methods: {
    async getList () {
      const tmpData = this.proData
      tmpData.paymentMethod = this.ordPaymentMethod
      this.$loading.show()
      await selectCatAndClear(tmpData).then(res => {
        this.$loading.hide()
        if (res.result >= 0) {
          this.dataList = res.resultObject.clearShopVoList
          this.jiFenYuJiL = res.resultObject.jfzs
          let num = 0
          // 促销立减eventPrice
          let eventPrice = 0
          // 市场价marketPrice
          let marketPrice = 0
          // 线上价clearPrice
          let clearPrice = 0
          // 商家返利rebatePrice
          let rebatePrice = 0
          // 运费
          let deliverPrice = 0
          // 实付合计
          let price = 0
          this.dataList.forEach(i => {
            eventPrice = accAdd(eventPrice, i.eventPrice)
            marketPrice = accAdd(marketPrice, i.marketPrice)
            clearPrice = accAdd(clearPrice, i.clearPrice)
            rebatePrice = accAdd(rebatePrice, i.rebatePrice)
            deliverPrice = accAdd(deliverPrice, i.deliverPrice)
            price = accAdd(price, i.price)
            i.clearEventVo.forEach(j => {
              const a = switchYouHui(j.eventType)
              j.youhuiName = a
              if (j.clearGiftVo) {
                const giftStatus = j.clearGiftVo.some(h => {
                  return h.proSpecificationUnit
                })
                j.giftStatus = giftStatus
              }
              j.clearProduct.forEach(k => {
                num = accAdd(num, k.productNum)
              })
            })
          })
          this.totalNum = num
          this.eventPrice = eventPrice
          this.marketPrice = marketPrice
          this.clearPrice = clearPrice
          this.rebatePrice = rebatePrice
          this.deliverPrice = deliverPrice
          this.price = price
          this.initPrice = price
          if (this.price > 50000) {
            for (let i = this.paymethodList.length - 1; i >= 0; i--) {
              if (this.paymethodList[i].ptName === '微信支付' || this.paymethodList[i].ptName === '支付宝支付') {
                this.paymethodList.splice(i, 1)
              }
            }
            this.ordPaymentMethod = this.paymethodList[0]?.ptEnum || ''
            this.pcEnum = this.paymethodList[0]?.pcEnum || ''
          }
        } else {
          Toast(res.message)
        }
      }).catch(err => {
        this.$loading.hide()
        console.error(err)
      })
    },
    // 获取地址
    getAddress () {
      selectShoppingCartDefaultAddress().then(res => {
        if (res.resultObject) {
          this.addressData = res.resultObject
          console.log(this.addressData)
        } else {
          this.addtessStatus = false
        }
      }).catch(err => {
        this.$loading.hide()
        console.error(err)
      })
    },
    // 获取积分
    async getJiFen () {
      const mid = this.proData.ordSellerMid
      const tmpData = {
        mid,
        ordPrice: this.initPrice
      }
      await selectOrdIsUsingCumScore(tmpData).then(res => {
        this.jiFenMax = res.resultObject.canUsingCumScore
        this.jiFenValid = res.resultObject.isValid
        this.jiFenText = res.resultObject.message
      }).catch(err => {
        console.error(err)
      })
    },
    getJiFenRule () {
      const mid = this.proData.ordSellerMid
      const tmpData = {
        mid
      }
      this.$loading.show()
      queryDptCumScoreRule(tmpData).then(res => {
        this.$loading.hide()
        const jiFenRule = res.resultObject
        this.jiFenRule = jiFenRule
      }).catch(err => {
        console.error(err)
      })
    },
    // 小程序需要解析才能获取数据
    async getwxmindata () {
      const tmpData = {
        data: this.reData
      }
      await getwxmindata(tmpData).then(res => {
        this.proData = res.resultObject
      }).catch(err => {
        console.error(err)
        Toast('订单超时请重新下单')
        setTimeout(() => {
          wx.miniProgram.switchTab({
            url: '/pages/shopCart/shopCart',
            fail: err => {
              console.error(err)
              Toast(err)
            }
          })
        }, 2000)
      })
    },
    // 创建订单
    commonOrderCreate () {
      let ordPaymentMethod = ''
      let ordPaymentType = ''
      if (this.ordPaymentMethod === 'EMPTY') {
        ordPaymentMethod = 'EMPTY'
        ordPaymentType = 'ONLINE'
      } else {
        ordPaymentMethod = this.ordPaymentMethod
        ordPaymentType = 'ONLINE'
      }
      const list = []
      this.dataList.forEach(i => {
        i.clearEventVo.forEach(j => {
          j.clearProduct.forEach(k => {
            k.shopName = i.shopName
            list.push(k)
          })
        })
      })
      const tmpList = list.map(i => ({
        ordProductBarCode: i.barCode,
        ordProductBrandId: i.brandId,
        ordProductBrandName: i.brandName,
        ordProductEventId: i.eventId,
        ordProductId: i.proId,
        ordProductImage: i.proImage,
        ordProductManufacturer: i.manufacturer,
        ordProductName: i.proName,
        ordProductNum: i.productNum,
        ordProductProSpeUnitId: i.proSpecificationUnit,
        ordProductSpecId: i.specId,
        ordProductSpecName: i.specName,
        ordProductUnitId: i.unitId,
        ordProductUnitName: i.unitName,
        ordProductShopId: i.shopId,
        ordProductIsGift: 0
      }))
      if (this.addtessStatus2 === false) {
        if (!this.userPhone) {
          Toast('请输入电话号码')
          return
        }
        if (!this.userName) {
          Toast('请输入姓名')
          return
        }
        this.addressData.addressContactPhone = this.userPhone
        this.addressData.addressContactName = this.userName
      }
      const tmpData = {
        ordAddressId: this.addressData.id,
        ordAddressName: this.addressData.addressPname + this.addressData.addressCname + this.addressData.addressAname + this.addressData.addressDetail,
        ordComment: this.note,
        ordIsPrint: 0,
        ordPaymentMethod,
        ordPaymentType,
        ordResource: this.ordResource,
        ordSellerMid: this.proData.ordSellerMid,
        ordType: 10,
        orderProductAoList: tmpList,
        ordAddressUserName: this.addressData.addressContactName,
        jsCode: this.jsCode,
        ordAddressUserPhone: this.addressData.addressContactPhone,
        useCumScore: this.jiFenVal,
        ordSellerShopId: tmpList[0].ordProductShopId
      }
      const resData = JSON.stringify(tmpData)
      const sessionID = this.sessionID
      tt.miniProgram.redirectTo({
        url: `/pages/thirdpayment/thirdpayment?data=${resData}&sessionID=${sessionID}`,
        fail: err => {
          console.error(err)
          Toast(err)
        }
      })
    },
    submit () {
      // 判断资料完善情况
      dptCommonVerify().then(res => {
        const infoStatus = res.resultObject.label_all
        if (infoStatus) {
          this.commonOrderCreate()
        } else {
          Dialog.confirm({
            title: '提示',
            message: '为了更好的为您服务，请先完善资料'
          }).then(() => {
            tt.miniProgram.navigateTo({
              url: '/pages/personCenter/completeInfo/completeInfo?from=H5',
              fail: err => {
                console.error(err)
                Toast(err)
              }
            })
          }).catch(() => {
            console.log('用户关闭了弹窗')
          })
        }
      }).catch(err => {
        console.error(err)
      })
    },
    changeRadio (e) {
      console.log(e)
      if (e.value === 'address') {
        this.addtessStatus2 = true
      } else {
        this.addtessStatus2 = false
      }
    },
    showJiFenRule () {
      this.jiFenRuleStatus = true
      this.getJiFenRule()
    },
    closeJiFenRule () {
      this.jiFenRuleStatus = false
    },
    jiFenInput () {
      var that = this
      if (that.jiFenVal > that.jiFenMax) {
        that.jiFenVal = that.jiFenMax
        calculate()
      } else {
        calculate()
      }
      function calculate () {
        // 去掉数字开头的0
        that.jiFenVal = that.jiFenVal.toString().replace(/\b(0+)/gi, '')
        that.jiFenPrice = that.jiFenVal / 100
        that.price = subtr(that.initPrice, that.jiFenPrice)
        // 支付价格小于0 只能货到付款
        parseFloat(that.price)
        const list = that.paymethodList
        if (that.price <= 0) {
          list.forEach(i => {
            if (i.ptEnum === 'WXPAY' || i.ptEnum === 'ALIPAY') {
              i.disabled = true
            }
          })
          that.ordPaymentMethod = 'EMPTY'
        } else {
          list.forEach(i => {
            i.disabled = false
          })
        }
      }
    },
    toAddress () {
      tt.miniProgram.navigateTo({
        url: '/pages/personCenter/address/address?select=true',
        fail: err => {
          console.error(err)
          Toast(err)
        }
      })
    }
  }

}
</script>

<style scoped lang="scss">
.contain {
  background-color: #F3F3F3;
  padding: 10px 10px 81px 10px;
  height: 100%;
}
.topnav {
  ::v-deep .van-nav-bar__left .van-icon{
    color: #000;
  }
  ::v-deep .van-nav-bar__right .van-icon{
    color: #000;
  }
}
.addressnav {
  margin-top: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  .address {
    display: flex;
    align-items: center;
  }
}
.order {
  border-radius: 4px;
  margin-top: 10px;
  background-color: #fff;
}
.order1 {
  .oa {
    padding: 10px 10px 8px 10px;
    border-bottom: 1px solid #f5f5f5;
  }
  .ob {
    .ob-1 {
      padding: 10px;
      display: flex;
      align-items: center;
      background-color: #f5f5f5;
      border: 1px solid #f0f0f0;
    }
    .ob-2 {
      padding: 10px;
      display: flex;
      border-bottom: 1px solid #f5f5f5;
    }
  }
}
.order2 {
  .oc {
    border-bottom: 1px solid #f5f5f5;
    .ocinput {
    }
    ::v-deep .ocinput span {
      font-size: 12px;
    }
    ::v-deep .ocinput .van-field__control {
      font-size: 12px;
    }
  }
  .od {
    padding: 10px;
    text-align: right;
    border-bottom: 1px solid #f5f5f5;
  }
  .oe {
    padding: 10px;
    border-bottom: 1px solid #f5f5f5;
    .oe-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 4px;
    }
    .oe-item3 {
      margin-top: 4px;
    }
  }
  .of {
    padding: 10px;
    border-bottom: 1px solid #f5f5f5;
    .of-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.paymethod {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 4px;
  .pa {
    padding: 10px;
    border-bottom: 1px solid #f5f5f5;
  }
  .pb {
    .pb-item {
      display: flex;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid #f5f5f5;
    }
  }
}
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 8px 10px 20px 10px;
  .bl {
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
  .br {
    width: 150px;
    text-align: center;
    color: #fff;
    background-color: #E40404;
    font-size: 16px;
    font-weight: bold;
    line-height: 44px;
    border-radius: 30px;
  }
}
.visibility {
  visibility: hidden;
}
.jifeninput {
  border: 1px solid #ccc;
  display: inline-block;
  width: 50px;
  height: 24px;
  line-height: 24px;
  padding: 0 0 0 6px;
  vertical-align: -6px;
}
.jifenpop {
  padding: 20px;
  width: 300px;
  .jifen-line {
    margin-top: 18px;
    .jifen-title {
      font-weight: bold;
      border-left: 8px solid #E40404;
      padding-left: 4px;
    }
    .jifen-text {
      margin-top: 8px;
    }
  }
}
</style>
